
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list, filteredList } from '@/guides/endpoints/news'

import Card from '@/components/Card.vue'
import Error from '@/components/Error.vue'
import Hero from '@/components/Hero.vue'
import SelectCustom from '@/components/SelectCustom.vue'

export default defineComponent({
  name: 'News',
  components: {
    Card,
    Error,
    Hero,
    SelectCustom,
  },
  setup() {
    const chromeData = ref()
    const data = ref()

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    list().then(r => {
      data.value = r.data.content
    })

    const filterBy = (month: string) => {
      console.log('month', month)

      filteredList(month).then(r => {
        data.value = r.data.content
      })
    }

    return {
      chromeData,
      data,
      filterBy,
    }
  },
})
