
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  name: 'Hero',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    picture: {
      type: Object,
      default: () => {
        return { src: '/assets/images/banner.jpg', alt: 'hero' }
      },
    },
  },
  setup() {
    const y = ref(window.scrollY)

    const stuck = computed(() => {
      return y.value > 115
    })

    const onScroll = () => {
      y.value = window.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      stuck,
    }
  },
})
