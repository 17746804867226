<template>
  <div class="image">
    <img v-if="picture && picture.src" v-src="picture" />
    <img v-else src="@/assets/images/banner.jpg" />
  </div>

  <div class="content wrapper">
    <h1>{{ title }}</h1>
  </div>

  <div class="slot wrapper" :class="{ stuck }">
    <div class="slot__inner">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'

export default defineComponent({
  name: 'Hero',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    picture: {
      type: Object,
      default: () => {
        return { src: '/assets/images/banner.jpg', alt: 'hero' }
      },
    },
  },
  setup() {
    const y = ref(window.scrollY)

    const stuck = computed(() => {
      return y.value > 115
    })

    const onScroll = () => {
      y.value = window.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      stuck,
    }
  },
})
</script>

<style lang="scss" scoped>
.image {
  position: relative;
  width: 100%;
  @include fluid(
    height,
    (
      xxs: 150px,
      m: 225px,
    )
  );

  &::after {
    content: '';
    display: block;
    @include get-all-space;
    background: linear-gradient(
      358.52deg,
      rgba(0, 0, 0, 0.2) 17.03%,
      rgba(0, 0, 0, 0) 50.67%
    );
  }

  img {
    @include image-fit;
  }
}

.content {
  position: relative;
  z-index: 5;

  @include fluid(
    padding-top,
    (
      xxs: 25px,
      l: 70px,
    )
  );

  @include fluid(
    margin-top,
    (
      xxs: -150px,
      m: -225px,
    )
  );
}

.slot {
  position: relative;
  z-index: 3;
  padding-bottom: 1.5rem;
  max-width: none !important;

  @include mq($until: s) {
    padding-top: 1rem;
    position: sticky;
    align-self: flex-start;
    top: 55px;

    &.stuck {
      background: #fff;
      box-shadow: -3px 16px 15px 0px rgba(0, 0, 0, 0.1);
    }
  }

  @include fluid(
    margin-bottom,
    (
      xxxs: 30px,
      l: 120px,
    )
  );
}

.slot__inner {
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  @include liant-bold;
  @include fluid(
    font-size,
    (
      xxxs: 20px,
      mob: 32px,
      nav: 46px,
    )
  );
  line-height: 1.12;
  text-align: center;
  color: $tangerine;
}
</style>
