<template>
  <div class="news" v-if="data && chromeData">
    <Hero :title="data.title" :picture="chromeData?.headers.news">
      <SelectCustom
        v-if="data.news.items.length"
        @select="filterBy"
        :data="data.selectCustom"
      />
    </Hero>
    <transition name="fade" mode="out-in">
      <div class="news-inner" :key="data.news.title">
        <div v-if="data.news.items.length">
          <h3>{{ data.news.title }}</h3>

          <Card
            :data="news"
            v-for="news in data.news.items"
            :key="`news-${news.title}`"
          />
        </div>
        <div v-else>
          Aucune actualité
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <Error />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { list, filteredList } from '@/guides/endpoints/news'

import Card from '@/components/Card.vue'
import Error from '@/components/Error.vue'
import Hero from '@/components/Hero.vue'
import SelectCustom from '@/components/SelectCustom.vue'

export default defineComponent({
  name: 'News',
  components: {
    Card,
    Error,
    Hero,
    SelectCustom,
  },
  setup() {
    const chromeData = ref()
    const data = ref()

    chrome().then(r => {
      chromeData.value = r.data
      console.log('chrome', r.data)
    })

    list().then(r => {
      data.value = r.data.content
    })

    const filterBy = (month: string) => {
      console.log('month', month)

      filteredList(month).then(r => {
        data.value = r.data.content
      })
    }

    return {
      chromeData,
      data,
      filterBy,
    }
  },
})
</script>

<style lang="scss" scoped>
.news-inner {
  margin: 0 $spacing * 1.35;

  ::v-deep {
    .card__picture-outer {
      @include mq(m) {
        width: 12rem;
      }
    }
  }

  @include mq(m) {
    width: calc(100% - 8rem);
    max-width: 600px;
    margin: 4rem auto;
  }
}

.card {
  margin-top: 1.5rem;

  @include mq(m) {
    margin-top: $spacing * 1.35;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
